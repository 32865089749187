import { useNavigate } from 'react-router-dom';
import SearchBox from '../components/SearchBox';
import Graph from '../components/Graph';
import qs from "qs";
import { useState } from 'react';
import useSWR from 'swr';
import { fetcher } from '../utils/apiClient';
import SearchItem from '../components/SearchItem';

function MainPage() {
  const navgiate = useNavigate();
  const [selects, setSelects] = useState([]);

  const selectQuery = qs.stringify({
    filters: {
      id: {
        $in: selects,
      },
    }
  }, { encodeValuesOnly: true });
  
  const { data: searchData } = useSWR(`/graphs`, url => {
    return fetcher(url);
  });

  const { data: graphData } = useSWR(`graphs?${selectQuery}`, url => {
    if (selects.length === 0) return;

    return fetcher(url);
  });

  const onFocus = () => {
    navgiate("/search");
  }
  
  const onClick = (e) => {
    const graph_id = parseInt(e.currentTarget.dataset.graph_id);

    if (selects.includes(graph_id)) {
      setSelects(selects.filter(s => s !== graph_id));
  } else {
      setSelects([...selects, graph_id]);
    }
  };

  return (
    <div className="mt-6">
      <SearchBox onFocus={onFocus} />
      
      <div className="h-full flex justify-center">
        <Graph data={graphData} />
      </div>
      <div className="flex flex-col items-center my-6">
      { searchData && searchData.data.map(graph => <SearchItem 
          key={graph.id} 
          name={graph.attributes.name} 
          description={graph.attributes.description}
          graph_id={graph.id}
          selected={selects.includes(graph.id)}
          onClick={onClick}
      />) }
      </div>
    </div>
  )
}

export default MainPage;