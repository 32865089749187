import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import CtxText from "../components/CtxtText";
import useInput from "../hooks/useInput";
import apiClient from "../utils/apiClient";

function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  const [password, onPasswordChange] = useInput("");
  const [ctx, setCtx] = useState("none");

  const onSubmit = async e => {
    e.preventDefault();

    setCtx("none");

    try {
      await apiClient.post("/auth/reset-password", {
        password,
        passwordConfirmation: password,
        code: searchParams.get("code"),
      });

      setCtx("ok");
    } catch (e) {
      console.error(e.response);
      setCtx("error");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center my-80">
      <h1 className="font-bold text-4xl">비밀번호 변경</h1>
      <form onSubmit={onSubmit} className="p-6 w-80">
        <label className="mt-1 block">
          <span className="text-sm font-medium text-gray-700">
            비밀번호
          </span>
          <input
            type="password"
            name="password"
            value={password}
            onChange={onPasswordChange}
            disabled={!searchParams.get("code")}
            placeholder="새로운 비밀번호를 입력해주세요."
            className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1" 
          />
        </label>
        <input
          type="submit"
          value="비밀번호 변경하기"
          disabled={ctx === "ok" || !searchParams.get("code")}
          className="mt-3 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1 cursor-pointer"
        />
      </form>
      <CtxText 
        context={ctx}
        none="비밀번호를 변경합니다."
        error="비밀번호를 변경 할 수 없습니다."
        ok="비밀번호를 성공적으로 변경했습니다."
      />
    </div>
  );
}

export default ResetPasswordPage;