import axios from "axios";
import { getItem } from "./auth";

export const host =
  process.env.NODE_ENV === "development"
    ?
      "http://localhost:1337/api"
    : 
      "https://api.erooanalysis.com/api";

const apiClient = axios.create({
  baseURL: host,
  withCredentials: true,
  headers: {
    Authorization: getItem("jwt") ? `Bearer ${getItem("jwt")}` : undefined,
  }
});

export const fetcher = url => apiClient.get(url).then(resp => resp.data); 
export default apiClient;