import { useLayoutEffect, useMemo, useState } from "react";
import UPlotReact from 'uplot-react';
import normalize from "../utils/normalize";
import "/node_modules/uplot/dist/uPlot.min.css";

const colors = ["black" , "red", "green", "cyan", "yellow", "orange", "pink", "purple"];

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function Graph({ data }) {
  const [width] = useWindowSize();

  const options = {
    width: width * 3/5,
    height: 500,
    cursor: {
      drag: {
        x: true,
        y: true,
      },
    },
    scales: {
      x: {
        time: false,
      },
    },
    hooks: {
      drawAxes: [
        u => {
            let xLft = u.bbox.left;
            let xRgt = u.bbox.left + u.bbox.width;
            let yTop = u.bbox.top;
            let yBtm = u.bbox.top + u.bbox.height;

            
            u.ctx.save();
            u.ctx.beginPath();
            u.ctx.strokeStyle = "#333";
            u.ctx.lineWidth = 2;
            
            u.ctx.moveTo(xLft, yTop);
            u.ctx.lineTo(xLft, yBtm);
            u.ctx.lineTo(xRgt, yBtm);
            
            u.ctx.moveTo(xLft, yTop);
            u.ctx.lineTo(xRgt, yTop);

            u.ctx.moveTo(xRgt, yTop);
            u.ctx.lineTo(xRgt, yBtm);

            u.ctx.stroke();
            u.ctx.restore();
        }
      ]
    },
    axes: [
      {
        label: "Raman Shift (cm-1)",
        ticks: {
          show: false,
        },
      },
      {
        label: "Intensity (a.u.)",
        labelGap: 20,
        ticks: {
          show: false,
        }
      }
    ],
    series: [
      {
        label: "X",
      },
    ],
  }

  if (data?.data) {
    let i = 0;

    for(const graph of data.data) {
      let color = colors[i++] || "black";

      options.series.push({
        show: true,
        spanGaps: true,
        label: graph.attributes.name,
        stroke: color,
        width: 2,
      });
    }
  } else {
    options.series.push({
      show: true,
      label: "",
      width: 1,
      marker: {
        show: false,
      }
    });
  }


  const graphData = useMemo(() => normalize(data) || [[1], [1]], [data]);

  return (
    <div>
      <UPlotReact 
        options={options}
        data={graphData}
      />
    </div>
  );
}

export default Graph;