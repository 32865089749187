function SearchBox({ ...etc }) {
  return (
    <form className="p-6" onSubmit={(e) => e.preventDefault()}>
      <label className="block">
        <span className="text-sm font-medium text-gray-700">
          원하시거나, 궁금한 물질을 찾아보세요.
        </span>
        <input {...etc} type="text" name="search" placeholder="물질을 입력해주세요." className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1" />
      </label>
    </form>
  )
}

export default SearchBox;