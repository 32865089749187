function SearchItem({ name, description, graph_id, selected, onClick }) {
  return (
    <li className="flex items-center w-128 py-2 px-4 my-0.5 bg-white border-2 border-slate-400 rounded cursor-pointer shadow-md" onClick={onClick} data-graph_id={graph_id}>
      <input type="checkbox" checked={selected} readOnly />
      <div className="px-4">
        <h4 className="text-lg font-bold">{name}</h4>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
    </li>
  );
}

export default SearchItem;