import { Link, useParams, useSearchParams } from "react-router-dom";
import useSWR from "swr";
import qs from "qs";
import Post from "../components/Post";
import { fetcher } from "../utils/apiClient";
import dissection from "../utils/dissection"

const boardName = {
  "all": "전체",
  "free": "자유 게시판",
  "info": "정보 게시판",
  "official": "알림 게시판",
};

function CommunityPage() {
  const { type } = useParams();
  const [searchParams] = useSearchParams();

  const preQuery = {
    pagination: {
      page: Number(searchParams.get("page")) || 1,
      pageSize: 10,
    },
    fields: ["createdAt", "title"],
    populate: {
      author: {
        fields: ["username"],
      },
    }
  };

  if (type !== "all") {
    preQuery.filters = {
      type: { $eq: type },
    };
  }

  const query = qs.stringify(preQuery, { encodeValuesOnly: true });
  const { data } = useSWR(`/posts?${query}`, fetcher);

  return (
    <>
    <div className="flex max-w-[1200px] mx-auto mt-6">
      <Board type={type} />
      <div className="w-full">
        <div className="flex items-center justify-between px-6">
          <h1 className="p-3 text-3xl font-bold">{boardName[type]}</h1> 
          <Link to="/write" className="text-lg font-bold">글쓰기</Link>
        </div>
        <div className="flex mt-4 px-3">
          <div className="flex flex-1 justify-center font-bold">번호</div>
          <div className="w-1/2 justify-center font-bold">제목</div>
          <div className="flex flex-1 justify-center font-bold">작성자</div>
          <div className="flex flex-1 justify-center font-bold">작성일</div>
        </div>
        <div>
          {
            data && dissection(data).map(post => {
              return (
                <Post 
                  key={post.id}
                  title={post.attributes.title}
                  author={dissection(post.attributes.author).attributes.username}
                  date={new Date(post.attributes.createdAt).toLocaleDateString()}
                  url_id={post.id}
                />
              )
            })
          }
        </div>
      </div>
    </div>
    <Pagination type={type} page={Number(searchParams.get("page")) || 1}  pageCount={data?.meta.pagination.pageCount || 1} />
    </>
  );
}

function Board({ type }) {
  const selected = (t) => t === type ? " underline" : "";

  return (
    <div className="w-60 mt-4">
      <Link to="/community/all" className="flex">
        <h2 className={"text-xl font-bold px-4 py-1.5 rounded my-1 cursor-pointer" + selected("all")}>전체</h2>
      </Link>
      <Link to="/community/free" className="flex">
        <h2 className={"text-xl font-bold px-4 py-1.5 rounded my-1 cursor-pointer" + selected("free")}>자유 게시판</h2>
      </Link>
      <Link to="/community/info" className="flex">
        <h2 className={"text-xl font-bold px-4 py-1.5 rounded my-1 cursor-pointer" + selected("info")}>정보 게시판</h2>
      </Link>
      <Link to="/community/official" className="flex">
        <h2 className={"text-xl font-bold px-4 py-1.5 rounded my-1 cursor-pointer" + selected("official")}>알림 게시판</h2>
      </Link>
    </div>
  );
}

function Pagination({ type, page, pageCount }) {
  const pages = [];
  const pageStart = Math.floor((page - 1) / 5) * 5 + 1;

  if ((pageCount < pageStart + 4) && pageCount !== 1) {
    pages.push(<Link key="prev-page" to={`/community/${type}?page=${pageStart - 5}`} className="px-1.5 mx-1">&lt;&lt;</Link>);
  } 

  for (let i = pageStart; i <= pageStart + 4; i++) {
    if (i > pageCount) {
      break;
    } 

    if (i == page) {
      pages.push(<Link key={i} to={`/community/${type}?page=${i}`} className="px-1.5 mx-1 font-bold">{i}</Link>);
    } else {
      pages.push(<Link key={i} to={`/community/${type}?page=${i}`} className="px-1.5 mx-1">{i}</Link>);
    }
  }
  if (pageCount > pageStart + 4) {
    pages.push(<Link key="next-page" to={`/community/${type}?page=${pageStart + 5}`} className="px-1.5 mx-1">&gt;&gt;</Link>);
  }

  return (
    <div className="text-center mt-6">
      {pages}
    </div>
  );
}

export default CommunityPage;