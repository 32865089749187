import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useInput from "../hooks/useInput";
import useMe from "../hooks/useMe";
import apiClient from "../utils/apiClient";
import { login } from "../utils/auth";

function LoginPage() {
  const navgiate = useNavigate();
  const [err, setErr] = useState(null);
  const [email, onEmailChange] = useInput("");
  const [password, onPasswordChange] = useInput("");
  const { mutate } = useMe();

  const onSubmit = async(e) => {
    e.preventDefault();

    if (email.trim() === "" || password.trim() === "") {
      return setErr("빈칸을 채워주세요");
    }

    try {
      const response = await apiClient.post('/auth/local', {
        identifier: email,
        password: password,
      });

      navgiate("/");

      login(response.data);

      mutate();
    } catch (e) {
      const { message } = e.response.data.error;

      if (message === "Your account has been blocked by an administrator") {
        setErr("관리자 승인 후 로그인이 가능합니다.");
        return;
      }

      setErr("아이디 혹은 비밀번호가 틀립니다.");
    }

  };

  return (
    <div className="flex flex-col items-center justify-center my-80">
      <h1 className="font-bold text-4xl">로그인</h1>
      <form onSubmit={onSubmit} className="p-6 w-80">
        <label className="mt-1 block">
          <span className="text-sm font-medium text-gray-700">
            이메일
          </span>
          <input type="email" name="email" value={email} onChange={onEmailChange} placeholder="이메일을 입력해주세요." className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1" />
        </label>
        <label className="mt-1 block">
          <span className="text-sm font-medium text-gray-700">
            비밀번호
          </span>
          <input type="password" name="password" value={password} onChange={onPasswordChange} placeholder="비밀번호를 입력해주세요." className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1" />
        </label>
        <input type="submit" value="로그인" className="mt-3 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1 cursor-pointer"  />
      </form>
      {
        err == null 
          ? 
        <p className="text-gray-500 text-sm">
          비밀번호를 잊어버리셨으면 <Link className="text-blue-400" to="/forgot-password">여기를</Link> 클릭해주세요.
        </p>
          :
        <p className="text-red-500 text-sm">{err}</p>
      }
      
    </div>
  );
}

export default LoginPage;