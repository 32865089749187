import Loading from "../components/Loading";

function PlaygroundPage() {
  return (
    <div>
      <Loading />
    </div>
  );
}

export default PlaygroundPage;