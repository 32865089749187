import { Link } from "react-router-dom";
import useMe from "../hooks/useMe";

function Header() {
  const { me } = useMe();

  return (
    <>
      <header className="fixed z-50 top-0 left-0 w-full bg-white">
        <div className="max-w-screen-xl mx-auto">
          <div className="w-full h-16 px-8 flex flex-direciton items-center">
            <h1 className="font-bold text-xl"><Link to="/">이루어낼리시스</Link></h1>
            <nav className="inline-flex pl-3">
              <div className="font-bold px-5 text-base text-slate-900"><Link to="/">홈</Link></div>
              <div className="font-bold px-5 text-base text-slate-900"><Link to="/community/all">커뮤니티</Link></div>
              <div className="font-bold px-5 text-base text-slate-900"><Link to="/config">마이페이지</Link></div>
            </nav>
            <h1 className="ml-auto px-4 text-black">
              { 
                me 
                  ? 
                <Link to="/config" className="hover:underline">{me.username}</Link>
                  :
                <><Link to="/login">로그인</Link> &nbsp;&nbsp;|&nbsp;&nbsp; <Link to="/register">회원가입</Link></>
              }
            </h1>
          </div>
        </div>
      </header>
      <div className="h-16"></div>
    </>
  );
}

export default Header;