import { Link } from "react-router-dom";

function Post({ title, author, date, url_id }) {
  return (
    <Link to={`/post/${url_id}`}>
      <div className={"flex border-b border-gray-200 py-2 px-3 hover:bg-[#faf9f5] rounded-lg cursor-pointer "}>
        <div className="flex flex-1 text-sm text-slate-700 justify-center items-center">{url_id}</div>
        <div className="w-1/2">{title}</div>
        <div className="flex flex-1 text-sm text-slate-700 justify-center items-center">{author}</div>
        <div className="flex flex-1 text-sm text-slate-700 justify-center items-center">{date}</div>
      </div>
    </Link>
  );
}

export default Post;