import useSWR from "swr";
import { fetcher } from "../utils/apiClient";
import { getItem, setItem } from "../utils/auth";

function useMe() {
  const { data, error, mutate } = useSWR("/users/me", async url => { 
      try {
        const data = await fetcher(url);
        setItem("me", data);
  
        return data;
      } catch (e) {
        setItem("me", null);
        setItem("jwt", null);

        return null;
      }
    }, 
    { 
      fallbackData: getItem("me") ? getItem("me") : null,
    }
  );

  return { 
    me: data, 
    error, 
    mutate
  };
}

export default useMe;