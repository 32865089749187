import Editor from "draft-js-plugins-editor";
import { convertFromRaw, EditorState } from "draft-js";
import { Link, useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import qs from "qs";
import Button, { buttonSmall } from "../components/Button";
import Loading from "../components/Loading";
import apiClient, { fetcher } from "../utils/apiClient";
import dissection from "../utils/dissection";
import useInput from "../hooks/useInput";
import useMe from "../hooks/useMe";
import { useState } from "react";
import createImagePlugin from "draft-js-image-plugin";

const imagePlugin = createImagePlugin();
const plugins = [imagePlugin];

const typeToKorean = {
  "free": "자유",
  "info": "정보",
  "official": "공지", 
};

function PostPage() {
  const { id } = useParams();
  const { me } = useMe();
  const navgiate = useNavigate();
  const query = qs.stringify({
    populate: "*", 
  }, {
    encodeValuesOnly: true,
  });

  const query2 = qs.stringify({
    filters: {
      post: {
        id: {
          $eq: id,
        }
      }
    },  
    populate: {
      author: {
        fields: ["username" ,"id"],
      },
    },
  }, {
    encodeValuesOnly: true,
  });

  const { data: response } = useSWR(`/posts/${id}?${query}`, fetcher);
  const { data: response2, mutate: mutate2 } = useSWR(`/comments?${query2}`, fetcher);
  
  const post = dissection(response);
  const comments = dissection(response2);

  const editorState = post ? EditorState.createWithContent(convertFromRaw(JSON.parse(post.attributes.body))) : null;

  const deletePost = async() => {
    await apiClient.post("/custom/delete-post", { 
      data: {
        post_id: id,
      },
    });

    navgiate("/community/all");
  };

  if (!post || !comments) {
    return (
      <div className="bg-white p-6 m-12 rounded-lg max-w-[900px] mx-auto h-80 flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="bg-white p-6 m-12 rounded-lg max-w-[900px] mx-auto">
      <div className="my-2">
        <span className="bg-red-300 text-orange-700 font-bold rounded px-1.5 py-1">{typeToKorean[post.attributes.type]}</span>
      </div>
      <h1 className="font-bold text-2xl">{post.attributes.title}</h1>
      <div className="flex justify-between">
        <div>
          <Link to={`/user/${dissection(post.attributes.author).id}`} className="hover:underline">
            <span className="my-2 mr-1">{dissection(post.attributes.author).attributes.username}</span>
          </Link>
          <span className="my-2 ml-1 text-slate-500 line-2">{new Date(post.attributes.createdAt).toLocaleDateString()}</span>
        </div> 
        { me?.id === dissection(post.attributes.author).id && <span onClick={deletePost} className="my-2 text-blue-500 line-2 cursor-pointer">삭제</span>}
      </div>
      <FileList files={JSON.parse(post.attributes.files)} />
      <div className="my-6">
        {
          editorState
            ?
          <Editor
            plugins={plugins}
            editorState={editorState}
            readOnly={true}
            placeholder="내용을 입력하세요."
            onChange={null}
          />
            :
          null
        }
      </div>

      <Comments comments={comments} post_id={id} mutate2={mutate2} me={me} />
    </div>
  );
}

function Comments({ comments, post_id, me, mutate2 }) {
  const [reply, setReply] = useState({ id: null, alam: "" });
  const [comment, onChange, onReset] = useInput("");

  const submit = async() => {
    if (comment.trim() === "") return;

    onReset();

    const data = {
      body: comment,
      post: post_id,
    };

    if (reply.id) {
      data.parent_comment_id = reply.id;
    }

    await apiClient.post("/custom/comments/write", {
      data,
    });

    await mutate2();
  };

  return (
    <div>
      <hr className="my-4 border-gray-300" />
      <div>
        <h3 className="font-bold my-2">댓글</h3>
        {comments.map(comment => {
          if (comment.attributes.parent_comment_id) return;

          return (
            <Comment user_id={me?.id} mutate2={mutate2} id={comment.id} comments={comments} setReply={setReply} author={dissection(comment.attributes.author)} body={comment.attributes.body} />
          );
        })}
      </div>
      <div className="mt-3">
        <h3 className="font-bold mb-1">댓글 쓰기</h3>
        {reply.alam && <p className="my-1 ml-1 text-gray-500 text-sm">{reply.alam} &nbsp;|&nbsp; <span onClick={()=>{setReply({ id: null, alam: "" })}} className="cursor-pointer text-blue-500">취소</span></p>}
        <textarea placeholder="댓글을 작성해보세요." value={comment} onChange={onChange} disabled={!me} className="w-full resize-none rounded-lg border-solid border-2 border-slate-200 p-2"  />
        <div className="flex justify-end">
          <Button onClick={submit} disabled={!me} className={buttonSmall}>등록</Button>
        </div>
      </div>
    </div>
  );
}

function Comment({ user_id ,id, author, comments, mutate2, body, setReply }) {
  const onClick = () => {
    setReply({
      id,
      alam: `> ${body}에 답글달기`,
    });
  }

  const onClickDelete = async() => {
    await apiClient.post("/custom/delete-comment", {
      data: {
        comment_id: id,
      },
    });
    await mutate2();
  };

  return (
    <div>
      <div className="flex justify-between">
        <h2 className="font-bold text-sm">{author.attributes.username}</h2>
        <div>
          <span className="text-sm text-blue-500 cursor-pointer" onClick={onClick}>답글달기</span>
          {user_id === author.id && <>
            <span className="text-sm text-blue-500">&nbsp;|&nbsp;</span>
            <span className="text-sm text-blue-500 cursor-pointer" onClick={onClickDelete}>삭제</span></>
          }
        </div>
      </div>
      <p>{body}</p>
      {comments.map(comment => {
        if (comment.attributes.parent_comment_id !== id) return;

        return (
          <div className="ml-3">
            <Comment
              mutate2={mutate2}
              id={comment.id}
              comments={comments}
              setReply={setReply}
              author={dissection(comment.attributes.author)}
              user_id={user_id}
              body={comment.attributes.body}
            />
          </div>
        );
      })}
    </div>
  );
}

function FileList({ files }) {
  if (!files) return null;

  return (
    <div>
      <h1 className="font-bold">업로드 된 파일</h1>
      {files.map(file => (
        <div>
          <a href={file.url} download={file.name} className="text-blue-500 cursor-pointer">
            {file.name}
         </a>
        </div>
      ))}
    </div>
  );
}

export default PostPage;