import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";
import qs from "qs";
import Graph from "../components/Graph";
import SearchBox from "../components/SearchBox";
import SearchItem from "../components/SearchItem";
import useInput from "../hooks/useInput";
import useDebounce from "../hooks/useDebounce";
import { fetcher } from "../utils/apiClient";

function SearchPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, onChange] = useInput(searchParams.get("q") || "");
  const [cache, setCache] = useState(null);
  const [selects, setSelects] = useState(searchParams.getAll("selects").map(x => parseInt(x)));
  const debounceText = useDebounce(searchText, 350);
  const firstUpdate = useRef(true);

  const query = qs.stringify({ 
    filters: {
      name: {
        $containsi: debounceText,
      },
    },
  }, { encodeValuesOnly: true });
  const selectQuery = qs.stringify({
    filters: {
      id: {
        $in: selects,
      },
    }
  }, { encodeValuesOnly: true });


  const { data: searchData } = useSWR(`/graphs?${query}`, url => {
    if (!debounceText) return;

    return fetcher(url);
  });
  
  const { data: graphData } = useSWR(`graphs?${selectQuery}`, url => {
    if (selects.length === 0) return;

    return fetcher(url);
  });

  useEffect(() => {
    if (debounceText === "") {
      setCache(null);
    } else if (searchData) {
      setCache(searchData);
    }
  }, [searchData, debounceText]);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (debounceText === '') return;

    setSelects([]);
    setSearchParams({ q: debounceText }, { replace: true });
  }, [debounceText, setSearchParams]);

  const onClick = (e) => {
    const graph_id = parseInt(e.currentTarget.dataset.graph_id);

    if (selects.includes(graph_id)) {
      setSelects(selects.filter(s => s !== graph_id));
      setSearchParams({ q: debounceText, selects: selects.filter(s => s !== graph_id)  }, { replace: true });
  } else {
      setSelects([...selects, graph_id]);
      setSearchParams({ q: debounceText, selects: [...selects, graph_id] }, { replace: true });
    }
  };

  return (
    <>
      <div className="mt-6">
        <SearchBox value={searchText} onChange={onChange} autoFocus={true}  />
        <div className="h-full flex justify-center">
          <Graph data={graphData} />
        </div>
        <div className="flex flex-col items-center my-6">
          { searchData ? searchData.data.map(graph => <SearchItem 
            key={graph.id} 
            name={graph.attributes.name} 
            description={graph.attributes.description}
            graph_id={graph.id}
            selected={selects.includes(graph.id)}
            onClick={onClick}
          />) : cache && cache.data.map(graph => <SearchItem 
            key={graph.id} 
            name={graph.attributes.name} 
            description={graph.attributes.description}
            graph_id={graph.id}
            selected={selects.includes(graph.id)}
            onClick={onClick}
          />)
          }
        </div>
      </div>
    </>
  )
}

export default SearchPage;