import { useState } from "react";
import useInput from "../hooks/useInput";
import apiClient from "../utils/apiClient";

function RegisterPage() {
  const [step, setStep] = useState(0);
  const [err, setErr] = useState(null);
  const [name, onNameChange] = useInput("");
  const [email, onEmailChange] = useInput("");
  const [password, onPasswordChange] = useInput("");

  const onSubmit = async(e) => {
    e.preventDefault();

    if (step === 0) return setStep(step + 1);
    if (name.trim() === "" || email.trim() === "" || password.trim() === "") {
      return setErr("빈칸을 채워주세요");
    }

    try {
      await apiClient.post('/auth/local/register', {
        username: name,
        email,
        password,
      });
    } catch (e) {
      console.error(e);
    }
    
    setStep(2);
  };

  return (
    <div className="flex flex-col items-center justify-center my-80">
      <h1 className="font-bold text-4xl">회원가입</h1>
      <form onSubmit={onSubmit} className="p-6 w-80">
        { step === 0 && <NameInput name={name} onNameChange={onNameChange} /> }
        { step === 1 && <AuthInput email={email} password={password} onEmailChange={onEmailChange} onPasswordChange={onPasswordChange}  /> }
        { step === 2 && <RegisterWait /> }
      </form>
      {
        err == null 
          ? 
        <p className="text-gray-500 text-sm">이용약관 및 개인정보처리방침에 동의합니다.</p>
          :
        <p className="text-red-500 text-sm">{err}</p>
      }
    </div>
  );
}

function NameInput({ name, onNameChange }) {
  return (
    <>    
      <label className="mt-1 block">
        <span className="text-sm font-medium text-gray-700">
          이름
        </span>
        <input type="name" name="name" value={name} onChange={onNameChange} placeholder="이름을 입력해주세요." className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1" />
      </label>
      <input type="submit" value="다음으로" className="mt-3 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1 cursor-pointer"  />
    </>
  );
}

function AuthInput({ email, password, onEmailChange, onPasswordChange }) {
  return (
    <>    
      <label className="mt-1 block">
        <span className="text-sm font-medium text-gray-700">
          이메일
        </span>
        <input type="email" name="email" value={email} onChange={onEmailChange} placeholder="이메일을 입력해주세요." className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1" />
      </label>
      <label className="mt-1 block">
        <span className="text-sm font-medium text-gray-700">
          비밀번호
        </span>
        <input type="password" name="password" value={password} onChange={onPasswordChange} placeholder="비밀번호를 입력해주세요." className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1" />
      </label>
      <input type="submit" value="계정 만들기" className="mt-3 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1 cursor-pointer"  />
    </>
  );
}

function RegisterWait() {
  return (
    <p className="text-gray-500 text-lg text-center">회원가입이 완료되었습니다. 관리자 승인 후 서비스를 이용하실 수 있습니다.</p>
  )
}

export default RegisterPage;