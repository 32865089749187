import React from "react";
import { BrowserRouter, Route, Routes,  } from "react-router-dom";
import Header from "./components/Header";
import CommunityPage from "./pages/CommunityPage";
import ConfigPage from "./pages/ConfigPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import LoginPage from "./pages/LoginPage";
import MainPage2 from "./pages/MainPage2";
import PlaygroundPage from "./pages/Playground";
import PostPage from "./pages/PostPage";
import RegisterPage from "./pages/RegisterPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import SearchPage from "./pages/SearchPage";
import UesrPage from "./pages/UserPage";
import WritePage from "./pages/write/WritePage";

function App() {
  return (
    <BrowserRouter>
      <Header />

      <main className="w-3/5 mx-auto">
        <Routes>
          <Route path="/" element={<MainPage2 />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/config" element={<ConfigPage />} />
          <Route path="/user/:id" element={<UesrPage />} />

          <Route path="/community/:type" element={<CommunityPage />} />
          <Route path="/post/:id" element={<PostPage />} />
          <Route path="/write" element={<WritePage />} />
          <Route path="/playground" element={<PlaygroundPage />} />
        </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;