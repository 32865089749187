import { sortedUniq, sortBy } from "lodash";

const normalize = (graphData) => {
  if (!graphData) return null;

  const result = [];
  let resultXAxis = [];

  const elements = [];

  for(const graph of graphData.data) {
    const element = {
      name: graph.attributes.name,
      data: [],
    };

    for (const line of graph.attributes.data.split("\n")) {
      if (!line) continue;

      const [x, y] = line.split("\t").map(s => parseFloat(s));

      resultXAxis.push(x);
      element.data.push({ x, y });
    }
    
    elements.push(element);
  }

  resultXAxis = sortedUniq(sortBy(resultXAxis));
  result.push(resultXAxis);


  for (const element of elements) {
    let yAxis = [];
    let pos = 0;
    console.log(element.data.length)
    for (const { x, y } of sortBy(element.data, "x")) {
      for (let i = pos; i < resultXAxis.length; i++) {
        if (x === resultXAxis[i]) {
          yAxis.push(y);
          pos = i + 1;
          break;
        } else {
          yAxis.push(null);
        }
      }
    }

    if (yAxis.length < resultXAxis.length) {
      yAxis = yAxis.concat(new Array(resultXAxis.length - yAxis.length).fill(null));
    }

    result.push(yAxis);
  }

  return result;
};

export default normalize;