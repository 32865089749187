export const buttonSmall = "px-4 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-md focus:ring-1 cursor-pointer ";
export const buttonMedium = "px-3 py-2 bg-white text-sm border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-md focus:ring-1 cursor-pointer ";

function Button({ children, ...etc }) {
  return (
    <>
      <button 
        {...etc}
      >
        {children}
      </button>
    </>
  )
}

export default Button;