import { useState } from "react";
import CtxText from "../components/CtxtText";
import useInput from "../hooks/useInput";
import apiClient from "../utils/apiClient";

function ForgotPasswordPage() {
  const [email, onEmailChange] = useInput("");
  const [ctx, setCtx] = useState("none");

  const onSubmit = async e => {
    e.preventDefault();

    setCtx("none");

    try {
      await apiClient.post("/auth/forgot-password", {
        email,
      });

      setCtx("ok");
    } catch (e) {
      console.error(e.response);
      setCtx("error");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center my-80">
      <h1 className="font-bold text-4xl">비밀번호 찾기</h1>
      <form onSubmit={onSubmit} className="p-6 w-80">
        <label className="mt-1 block">
          <span className="text-sm font-medium text-gray-700">
            이메일
          </span>
          <input type="email" name="email" value={email} onChange={onEmailChange} placeholder="가입한 이메일을 입력해주세요." className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1" />
        </label>
        <input
          type="submit"
          value="이메일 발송"
          disabled={ctx === "ok"}
          className="mt-3 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1 cursor-pointer"
        />
      </form>
      <CtxText 
        context={ctx}
        none="이메일을 통해 비밀번호 재설정 링크를 보내드립니다."
        error="이메일을 통해 비밀번호 재설정 링크를 전달할 수 없습니다."
        ok="이메일로 비밀번호 재설정 링크를 전달하였습니다."
      />
    </div>
  );
}

export default ForgotPasswordPage;