import apiClient from "./apiClient";

export function getItem(name) {
  return JSON.parse(localStorage.getItem(name));
}

export function setItem(name, data) {
  localStorage.setItem(name, JSON.stringify(data));
}

export function login({ user, jwt }) {
  apiClient.defaults.headers = {
    Authorization: `Bearer ${jwt}`
  }

  setItem("me", user);
  setItem("jwt", jwt);
}

export function logout() {

}