import { useParams } from "react-router-dom";
import useSWR from "swr";
import qs from "qs";
import Loading from "../components/Loading";
import { fetcher } from "../utils/apiClient";
import dissection from "../utils/dissection";
import Post from "../components/Post";

function UesrPage() {
  const { id } = useParams();
  const query = qs.stringify({
    filters: {
      author: {
        id: { $eq: id },
      },
    },
  }, { encodeValuesOnly: true });

  const { data: user } = useSWR(`/users/${id}`, fetcher);
  const { data: post } = useSWR(`/posts?${query}`, fetcher);

  if (!user || !post) {
    return (
      <div className="bg-white p-6 m-12 rounded-lg max-w-[900px] mx-auto h-80 flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="bg-white p-6 m-12 rounded-lg max-w-[900px] mx-auto">
      <div className="my-2">
        <span className="bg-red-300 text-orange-700 font-bold rounded px-1.5 py-1">{user.is_admin ? "관리자" : "회원"}</span>
      </div>
      <h1 className="font-bold text-2xl">{user.username}</h1>
      <p>{new Date(user.createdAt).toLocaleDateString()}에 가입</p>
      <hr className="my-4 border-gray-300" />
      <h1 className="font-bold text-2xl">작성한 글</h1>
      <div className="flex mt-4 px-3">
          <div className="flex flex-1 justify-center font-bold">번호</div>
          <div className="w-1/2 justify-center font-bold">제목</div>
          <div className="flex flex-1 justify-center font-bold">작성자</div>
          <div className="flex flex-1 justify-center font-bold">작성일</div>
        </div>
        <div>
          {
            dissection(post).map(p => {
              return (
                <Post 
                  key={p.id}
                  title={p.attributes.title}
                  author={user.username}
                  date={new Date(p.attributes.createdAt).toLocaleDateString()}
                  url_id={p.id}
                />
              )
            })
          }
        </div>
    </div>
  )
}

export default UesrPage;