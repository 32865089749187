export default function dissection(value) {
  if (!value || typeof value !== "object" || Array.isArray(value)) {
    return null;
  }
  
  if (value["data"] === undefined) {
    return null;
  }

  const { data } = value;

  return data;
}
