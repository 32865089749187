import React, { useEffect, useState } from "react";
import { AtomicBlockUtils, convertToRaw, EditorState, RichUtils } from "draft-js";
import { ReactComponent as BoldIcon } from "../../svg/bold-solid.svg";
import { ReactComponent as HeadingIcon } from "../../svg/heading-solid.svg";
import { ReactComponent as ItalicIcon } from "../../svg/italic-solid.svg";
import { ReactComponent as StrikeThroughIcon } from "../../svg/strikethrough-solid.svg";
import { ReactComponent as UnderlineIcon } from "../../svg/underline-solid.svg";
import { ReactComponent as ImageIcon } from "../../svg/image-solid.svg";
import { ReactComponent as FileIcon } from "../../svg/file-solid.svg";
import Editor from "draft-js-plugins-editor";

import "draft-js/dist/Draft.css";
import "./Editor.css";
import { useNavigate } from "react-router-dom";
import Button, { buttonMedium } from "../../components/Button";
import useInput from "../../hooks/useInput";
import apiClient, { host } from "../../utils/apiClient";
import useUpload from "../../hooks/useUpload";
import createImagePlugin from "draft-js-image-plugin";

const imagePlugin = createImagePlugin();
const plugins = [imagePlugin];

const imageExt = ["gif" , "png", "jpg", "jpeg", "bmp"];
const etcExt = ["pdf", "hwp", "txt", "doc", "7z", "zip", "json", "mp3", "mp4", "mov", "ppt", "pptx", "xls", "csv", ...imageExt];

function WritePage() {
  const navgiate = useNavigate();
  const [title, onChange] = useInput("");
  const editor = React.useRef(null);
  const [filejson, setFileJson] = useState([]);
  const [err, setErr] = useState(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const onClick = async() => {
    const { data } = await apiClient.post("/custom/posts/write", {
      data: {
        title,
        body: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        files: JSON.stringify(filejson),
      }
    });

    navgiate(`/post/${data.id}`);
  };

  return (
    <div className="my-6">
      <div className="bg-white">
        <input value={title} onChange={onChange} placeholder="제목을 입력해주세요." className="text-3xl p-4 font-bold outline-0 w-full" />
        <div className="my-2 h-2 w-28 bg-gray-500 mx-4"></div>
        {err && <p>{err}</p>}
        <FileList files={filejson} setFileJson={setFileJson} />
        <Toolbox editorState={editorState} setEditorState={setEditorState} setErr={setErr} setFileJson={setFileJson} />
        <div className="editor mt-2 p-4 rounded h-full">
          <Editor
            ref={editor}
            editorState={editorState}
            onChange={setEditorState}
            placeholder="내용을 입력하세요."
            plugins={plugins}
          />
        </div>
      </div>
      <div className="flex mt-4">
        <Button onClick={onClick} className={buttonMedium + "ml-auto"}>글 등록하기</Button>
      </div>
    </div>
  );
}

function Toolbox({ editorState, setEditorState, setErr, setFileJson }) {
  const [imageUpload, imgFile] = useUpload();
  const [fileUpload, file] = useUpload();

  const onClick = (e) => {
    switch (e.currentTarget.dataset.id) {
      case "heading":
        setEditorState(RichUtils.toggleBlockType(editorState, "header-two"));
        break
      case "bold":
        setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
        break;
      case "italic":
        setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
        break;
      case "underline":
        setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
        break;
      case "strikethrough":
        setEditorState(RichUtils.toggleInlineStyle(editorState, "STRIKETHROUGH"));
        break;
          
    }
  };

  useEffect(() => {
    (async() => {
      if (!imgFile) return;

      const form = new FormData();
     
      form.append("files", imgFile);
      
      if (!imageExt.includes(imgFile[0].name.split('.').pop())) {
        setErr("해당 파일 확장자는 허락되지 않습니다");
      }

      try {
        const response = await apiClient.post("/upload", form, { 
          headers: { "Content-Type": "multipart/form-data" }
        });

        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", {
          src: host.replace("/api", "") + (response.data[0].formats.medium ? response.data[0].formats.medium.url : response.data[0].url),
          height: response.data[0].formats.medium ? response.data[0].formats.medium.height : response.data[0].height,
          width: response.data[0].formats.medium ? response.data[0].formats.medium.width : response.data[0].width,
        });
  
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(
          editorState,
          { currentContent: contentStateWithEntity },
        );
  
        setEditorState(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ')); 
      } catch (e) {
        console.error(e);
        const { message } = e.response.data.error;
        console.log(message);
      }
    })()
  }, [imgFile]);

  useEffect(() => {
    (async() => {
      if (!file) return;

      const form = new FormData();
     
      form.append("files", file);

      if (!etcExt.includes(file.name.split('.').pop())) {
        setErr("해당 파일 확장자는 허락되지 않습니다");
      }

      try {
        const response = await apiClient.post("/upload", form, { 
          headers: { "Content-Type": "multipart/form-data" }
        });

        setFileJson(filejson => [
          ...filejson,
          { name: file.name, url: host.replace("/api", "") + response.data[0].url },
        ]);
      } catch (e) {
        console.error(e);
        const { message } = e.response.data.error;
        console.log(message);
      }
    })()
  }, [file]);

  return (
    <div className="flex h-10 ml-2 mt-4 text-gray-500 ">
      <div onClick={onClick} data-id="heading" className="p-2 hover:bg-gray-100 cursor-pointer">
        <HeadingIcon className="h-5 mx-2" />
      </div>
      <div onClick={onClick} data-id="bold" className="p-2 hover:bg-gray-100 cursor-pointer">
        <BoldIcon className="h-5 mx-2" />
      </div>
      <div onClick={onClick} data-id="italic" className="p-2 hover:bg-gray-100 cursor-pointer">
        <ItalicIcon className="h-5 mx-2" />
      </div>
      <div onClick={onClick} data-id="underline" className="p-2 hover:bg-gray-100 cursor-pointer">
        <UnderlineIcon className="h-5 mx-2" />
      </div>
      <div onClick={onClick} data-id="strikethrough" className="p-2 hover:bg-gray-100 cursor-pointer">
        <StrikeThroughIcon className="h-5 mx-2" />
      </div>
      <div onClick={imageUpload} className="p-2 hover:bg-gray-100 cursor-pointer">
        <ImageIcon className="h-5 mx-2" />
      </div>
      <div onClick={fileUpload} className="p-2 hover:bg-gray-100 cursor-pointer">
        <FileIcon className="h-5 mx-2" />
      </div>
    </div>

  );
}

function FileList({ files, setFileJson }) {
  const onRemove = (file) => {
    setFileJson(files => {
      return files.filter(f => f.url !== file.url);
    })
  };

  return (
    <div className="px-4">
      <h1 className="font-bold">업로드 된 파일</h1>
      {files.map(file => (
        <div>
          <a href={file.url} download={file.name} className="text-blue-500 cursor-pointer">
            {file.name}
         </a>
          <a onClick={() => onRemove(file)} className="text-blue-500 cursor-pointer ml-1 text-sm">(삭제)</a>
        </div>
      ))}
    </div>
  );
}

export default WritePage;