function CtxText({ context, none, error, ok }) {
  return (
    <>
      {context === "none" && <p className="mt-1 ml-1 text-gray-500 text-sm">{none}</p>}
      {context === "error" && <p className="mt-1 ml-1 text-red-500 text-sm">{error}</p>}
      {context === "ok" && <p className="mt-1 ml-1 text-green-500 text-sm">{ok}</p>}
    </>
  )
}

export default CtxText;