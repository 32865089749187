import useMe from "../hooks/useMe";
import Button, { buttonMedium } from "../components/Button";
import useInput from "../hooks/useInput";
import apiClient from "../utils/apiClient";
import { useState } from "react";
import CtxText from "../components/CtxtText";
import { Link } from "react-router-dom";
import { setItem } from "../utils/auth";

function ConfigPage() {
  const { me, mutate } = useMe();
  const [username, onChangeUsername] = useInput(me?.username || "");
  const [usernameCtx, setUsernameCtx] = useState("none");

  const onSubmit = async e => {
    e.preventDefault();
    setUsernameCtx("none");

    try {
      await apiClient.put("/custom/users/user-profile", {
        data: {
          username, 
        },      
      });

      setUsernameCtx("ok");
    } catch (e) {
      console.error(e);
      setUsernameCtx("error");
    }

    await mutate();
  }
  
  const logout = async e => {
    setItem("me", null);
    setItem("jwt", null);

    window.location.href = "/";
  };

  if (!me) {
    return null;
  }

  return (
    <div className="mt-6 w-4/5 mx-auto">
      <form onSubmit={onSubmit}>
        <InputBox subject="이름" type="name" value={username} onChange={onChangeUsername} placeholder="새로운 이름을 입력해주세요." />
        
        <div className="mt-4">
          <Button type="submit" className={buttonMedium}>변경내용 저장</Button>
          <CtxText
            context={usernameCtx}
            none={null}
            error="이름을 변경 할 수 없습니다."
            ok="이름이 변경되었습니다."
          />
        </div>
      </form>
      <p className="mt-1 ml-1 text-gray-500 text-sm">이메일 변경은 관리자에게 문의해주세요.</p>

      <hr className="my-4 border-gray-300" />
      <div className="mt-2">
        <div className="mt-4">
          <Link to="/forgot-password">
            <Button className={buttonMedium}>비밀번호 변경</Button>
          </Link>
        </div>
        <div className="mt-4">
          <Button onClick={logout} className={buttonMedium}>로그아웃</Button>
          <p className="mt-1 ml-1 text-gray-500 text-sm">현재 계정에서 로그아웃 합니다.</p>
        </div>            
        <div className="mt-4">
          <p className="mt-1 ml-1 text-gray-500 text-sm">탈퇴를 원하시면 관리자(02-393-2588)에게 문의해주세요.</p>
        </div>        
      </div>
      <hr className="my-4 border-gray-300" />
    </div>
  );
}

function InputBox({ subject, type, value, onChange, placeholder, description, disabled = false }) {
  return (
    <div>
      <label className="mt-1 block">
        <span className="text-sm font-medium text-gray-700">
          {subject}
        </span>
        <input
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          className="w-[210px] mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md focus:ring-1"
        />
        {description && <p className="mt-1 ml-1 text-gray-500 text-sm">{description}</p>}
      </label>
    </div>
  );
}

export default ConfigPage;